<template>
  <!-- eslint-disable  -->
  <div>
    <router-view v-if="$route.name === 'mr歷史紀錄'" />
    <div v-if="$route.name === 'mr提現紀錄'">
      <v-card flat class="w100p">
        <!--  -->
      </v-card>
      <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
        提現紀錄
        <v-spacer></v-spacer>
      </div>

      <FilterBox
        :open.sync="isFilterOpen"
        v-model="filter"
        :loading="isLoading"
        :options="filterOptions"
        @search="fetchData"
      />

      <v-card
        outlined
        :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
      >
        <v-card-text class="d-none d-sm-flex align-center">
          <v-dialog v-model="isInfoOpen" v-if="$vuetify.breakpoint.smAndDown">
            <v-card>
              <v-card-text class="py-3">
                可提現金額： {{ result.header_info.amt || 0 }} 元
                <br />
                凍結資金： {{ result.header_info.frozen_amt || 0 }} 元
                <br />
                單筆最低提現： {{ result.header_info.single_min_amt || 0 }} 元
                <br />
                單筆最高提現： {{ result.header_info.single_max_amt || 0 }} 元
                <br />
                單筆手續費： {{ result.header_info.fee || 0 }} 元
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>備註：手續費將從餘額裡扣除</span>
                </v-tooltip>
              </v-card-text>
            </v-card>
          </v-dialog>
          <div v-else class="d-flex align-center flex-wrap mb-4 mb-sm-0">
            可提現金額： {{ result.header_info.amt || 0 }} 元
            <v-divider vertical class="mx-3"></v-divider>
            凍結資金： {{ result.header_info.frozen_amt || 0 }} 元
            <v-divider vertical class="mx-3"></v-divider>
            單筆最低提現： {{ result.header_info.single_min_amt || 0 }} 元
            <v-divider vertical class="mx-3"></v-divider>
            單筆最高提現： {{ result.header_info.single_max_amt || 0 }} 元
            <v-divider vertical class="mx-3"></v-divider>
            單筆手續費： {{ result.header_info.fee || 0 }} 元
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on" class="ml-2">
                  mdi-alert-circle
                </v-icon>
              </template>
              <span>備註：手續費將從餘額裡扣除</span>
            </v-tooltip>
          </div>
          <v-divider vertical class="mx-3"></v-divider>
          <v-spacer></v-spacer>
          <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

          <SizeBox width="14" />
          <ExportBtn
            :items="result.list"
            :headers="headers"
            :file-name="`${
              $store.getters['app/getRouteTitle']
            } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
            sheet-name="sheetName"
          />
        </v-card-text>

        <DataTable
          :list="result.list"
          :total="result.total"
          :fields="headers"
          :replace="['bank_card_no', 'created_at']"
          @get-list="fetchData"
          :labels="labels"
          :loading="isLoading"
        >
          <template #item.bank_card_no="{ item }">
            <v-chip
              color="Secondary100"
              label
              small
              @click="openForm('BankInfo', item)"
            >
              {{ item.bank_card_no }}
            </v-chip>
          </template>
          <template #item.created_at="{ item }">
            <div>
              <div class="text-no-wrap">{{ item.created_at | formatTime }}</div>
              <div v-if="item.succeeded_at" class="text-no-wrap">
                {{ item.succeeded_at | formatTime }}
              </div>
              <div v-else class="text-no-wrap error--text">未完成</div>
            </div>
          </template>
        </DataTable>
      </v-card>

      <PayInfoForm
        :value="showingForm === 'BankInfo'"
        @close="showingForm = null"
        :passData="passData"
        :router-name="'mr歷史紀錄'"
      />
    </div>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import PayInfoForm from '@/components/MerchantPages/pay/history/PayInfoForm.vue';
import { getMerchantPayOrderList } from '@/api/merchantCenter/payOrders';

export default {
  components: {
    PayInfoForm
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '平台單號',
          value: 'platform_order_no',
          align: 'center',
          width: '180px'
        },
        { text: '代付狀態', value: 'status', align: 'center', width: '80px' },
        { text: '申請金額', value: 'amt', align: 'center', width: '120px' },
        {
          text: '申請者 IP',
          value: 'client_ip',
          align: 'center',
          width: '120px'
        },
        { text: '手續費', value: 'fee', align: 'center', width: '120px' },
        {
          text: '到帳金額',
          value: 'actual_amt',
          align: 'center',
          width: '120px'
        },
        {
          text: '收款姓名',
          value: 'bank_holder',
          align: 'center',
          width: '120px'
        },
        {
          text: '銀行卡號',
          value: 'bank_card_no',
          align: 'center',
          width: '120px'
        },
        {
          text: '銀行名稱',
          value: 'bank_name',
          align: 'center',
          width: '120px'
        },
        {
          text: '分行名稱',
          value: 'bank_branch',
          align: 'center',
          width: '120px'
        },
        {
          text: '建立時間\n/\n成功時間',
          value: 'created_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center',
          join: 'succeeded_at'
        },
        { text: '備註', value: 'note', align: 'center', width: '80px' }
      ],
      labels: {
        status: [
          { text: '未處理', value: 'pending', color: 'Secondary100' },
          { text: '處理中', value: 'in_progress', color: 'Error200' },
          { text: '已駁回', value: 'rejected', color: 'Error300' },
          { text: '已付款', value: 'paid', color: 'Secondary200' },
          { text: '已完成', value: 'completed', color: 'Primary050' }
        ]
      },
      filterOptions: [
        {
          label: '平台單號',
          type: 'text',
          name: 'filter.platform_order_no'
        },
        {
          label: '收款姓名',
          type: 'text',
          name: 'filter.bank_holder'
        },
        {
          label: '提現狀態',
          type: 'select',
          name: 'filter.status',
          items: [
            { text: '未處理', value: 'pending' },
            { text: '處理中', value: 'in_progress' },
            { text: '已駁回', value: 'rejected' },
            { text: '已付款', value: 'paid' },
            { text: '已完成', value: 'completed' }
          ]
        },
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'filter.created_at',
          range: true,
          withTime: true
        },
        {
          label: '完成時間',
          type: 'timeSet',
          name: 'filter.succeeded_at',
          range: true,
          withTime: true
        }
      ]
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const Orders = await getMerchantPayOrderList({
        ...this.filter,
        ...condition
      });
      if (!Orders.error) {
        this.result.header_info = Orders.merchant_info;
        this.result.total = Orders.total;
        this.result.page = Orders.page;
        this.$set(
          this.result,
          'list',
          Orders.items.map((item) => ({
            ...item
          }))
        );
      }

      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
