import { api } from "..";

export function getMerchantPayOrderInfo() {
  return api('/v1/merchantCenter/payOrders/getMerchantPayOrderInfo')
}

export function getPayOrderList(payload) {
  payload = { page: 1, limit: 20, ...payload }
  return api('/v1/merchantCenter/payOrders/getPayOrderList', payload);
}

export function getMerchantPayOrderList(payload) {
  payload = { page: 1, limit: 20, ...payload }
  return api('/v1/merchantCenter/payOrders/getMerchantPayOrderList', payload);
}

export function placeOrder(payload) {
  return api('/v1/merchantCenter/payOrders/placeOrder', payload)
}