<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        代付資料
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <SizeBox height="24"></SizeBox>

      <v-card-text v-if="passData">
        <v-card outlined>
          <v-card-title class="pos-r text-Heading6 OnSurface050">
            銀行資訊
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="2 OnSurface500--text text-Body">收款姓名</v-col>
              <v-col cols="auto OnSurface900--text text-DisplayExtraSmall">
                {{ passData.bank_holder }}
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col cols="2 OnSurface500--text text-Body">銀行卡號</v-col>
              <v-col cols="auto OnSurface900--text text-DisplayExtraSmall">
                {{ passData.bank_card_no }}
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col cols="2 OnSurface500--text text-Body">銀行名稱</v-col>
              <v-col cols="auto OnSurface900--text text-DisplayExtraSmall">
                {{ passData.bank_name }}
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col cols="2 OnSurface500--text text-Body">分行名稱</v-col>
              <v-col cols="auto OnSurface900--text text-DisplayExtraSmall">
                {{ passData.bank_branch }}
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions class="flex-center">
        <v-btn
          rounded
          color="primary"
          depressed
          width="320"
          height="40"
          @click="checkHistory"
        >
          查看歷史紀錄
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({})
    },
    routerName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      localData: {}
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.$set(this, 'localData', { ...this.passData });
    },
    reset() {
      this.$set(this, 'localData', {});
    },
    close() {
      this.$emit('close');
    },
    checkHistory() {
      this.close();
      this.$router.push({
        name: this.routerName,
        params: { bank_card_no: this.passData.bank_card_no }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
